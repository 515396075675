import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import {
  doesTypeArrayHavePkgTypes,
  getPkgTypesInZone,
} from 'lib/packageHelper';

import { Rail, RailContainer } from 'components/Rail';

import getPackagesForZone from '../getPackagesForZone';

import styles from './styles.module.scss';

function RightRailWithSplitRow({ packages }, context) {
  const pkgTypesInRail = getPkgTypesInZone(packages, 5);
  const hasTitle = doesTypeArrayHavePkgTypes(pkgTypesInRail, 'collectionTitle');

  const containerClasses = classNames(
    styles.rightRailWithSplitRow,
    { hasTitle },
    'layout-grid-container',
  );
  const mainClasses = classNames(
    styles.main,
    'layout-grid-item grid-col-7-l grid-col-8-xl',
  );
  const railClasses = classNames(
    styles.rail,
    'layout-grid-item grid-col-5-l grid-col-4-xl',
  );
  const splitColClasses = classNames(
    styles.splitCol,
    'layout-subgrid-item grid-fr-whole grid-fr-half-m grid-fr-whole-l grid-fr-half-xl',
  );

  return (
    <RailContainer className={containerClasses}>
      <div className={mainClasses}>
        {getPackagesForZone(1, packages, { ...context, isRailAdjacent: true })}

        <div className={classNames(styles.split, 'layout-subgrid-container')}>
          <div className={splitColClasses}>
            {getPackagesForZone(2, packages, { ...context, isRailAdjacent: true })}
          </div>
          <div className={splitColClasses}>
            {getPackagesForZone(3, packages, { ...context, isRailAdjacent: true })}
          </div>
        </div>

        {getPackagesForZone(4, packages, { ...context, isRailAdjacent: true })}
      </div>

      <Rail className={railClasses} packageTypes={pkgTypesInRail}>
        {getPackagesForZone(5, packages, { ...context, railContext: 'right' })}
      </Rail>
    </RailContainer>
  );
}

RightRailWithSplitRow.propTypes = {
  packages: PropTypes.arrayOf(CustomPropTypes.package).isRequired,
};

RightRailWithSplitRow.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export default RightRailWithSplitRow;
