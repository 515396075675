import { getAd } from './getAd';
import AdRules from './adRules.json';

const pageTypesWithoutAdditionalAds = [
  'entityPage',
];

const packagesToSplit = {
  twoUp: 'twoUp',
  threeUp: 'threeUp',
  fourUp: 'fourUp',
  threeFeatured: 'threeFeatured',
  threeFeaturedPlusList: 'threeFeaturedPlusList',
};

const shouldInsertAfter = {
  videoPkg: 'videoPkg',
  pancake: 'pancake',
  sevenUp: 'sevenUp',
  leadSectionFront: 'leadSectionFront',
  bigStoryTakeoverPlus4: 'bigStoryTakeoverPlus4',
  mosaic: 'mosaic',
};

const additionalVerticals = {
  globalcitizen: true,
};

const threeFeaturedPlusList = 'threeFeaturedPlusList';
const waffle = 'waffle';

const waffleRules = {
  boxinline: {
    firstSlot: 4, freq: 4, min: 4, last: false,
  },
  boxflex: { firstSlot: 3, freq: 6, min: 4 },
  midresponsive: {
    firstSlot: 27, min: 27, freq: Infinity, last: true,
  },
};

class FrontAdController {
  // Second param, is 'layout' in main AdController, unused here
  constructor(vertical, _, pageType) {
    this.currIdx = 0;
    this.lastAdIdx = -1;
    this.vertical = vertical;
    this.nonPackageAdsDisabled = pageTypesWithoutAdditionalAds.includes(pageType);
    // using "addCustomAds" name to match AdController, to allow for simplicity in getLayout.jsx
    // then renaming it to better describe the work that is happening in this method
    this.addCustomAds = this.parsePackages;
  }

  // First argument is 'layout' in main AdController, unused in this function
  parsePackages(_, packages = []) {
    if (
      (!AdRules[this.vertical] && !additionalVerticals[this.vertical])
      || !packages.length
    ) return packages;
    const newPackages = [];
    this.currIdx = 0;
    this.lastAdIdx = -1;

    if (packages[0].type === waffle) {
      const waffleItems = packages[0].items;
      // length before inserting ads
      this.pkgLength = waffleItems.length;

      // insert each type of Ad individually
      const waffleTablet = this.insertAdsIntoWaffle(waffleItems, 'boxflex');
      const waffleMobile = this.insertAdsIntoWaffle(waffleTablet, 'boxinline');
      const waffleWithAds = this.insertAdsIntoWaffle(waffleMobile, 'midresponsive');

      const newPkgs = packages.map((pkg, i) => {
        if (i === 0) {
          return {
            ...packages[0],
            items: waffleWithAds,
          };
        }
        return { ...pkg };
      });
      return newPkgs;
    }

    if (this.vertical === 'today') {
      // only inserts ads into Waffle for Today fronts
      // should skip all other ad splicing/insertions
      return packages;
    }

    packages.forEach((pkg, idx) => {
      const { type, zone, items } = pkg;

      const shouldSplit = packagesToSplit[type];
      const isThreePlusList = type === threeFeaturedPlusList;
      const isLast = idx === packages.length - 1;
      let newItems = [];

      if (shouldSplit) {
        // if type is three + list, the first 3 items are eligible for splicing
        const limit = isThreePlusList ? 3 : null;
        const { itemsWithAds, insertedAd } = this.insertAdsIntoPackage(
          items,
          limit,
          zone,
          isLast,
        );
        if (insertedAd) newItems = itemsWithAds;
      } else if (items.length) {
        this.currIdx += 1;
      }

      // haven't already inserted Ad in items && we're not at last package
      const canInsert = !newItems.length && !isLast;
      // certain long packages should be followed by an Ad
      if ((shouldInsertAfter[type] && !isLast) || (canInsert && this.shouldInsertAd())) {
        const ad = getAd(this.currIdx.toString(), zone);
        newPackages.push(pkg, ad);

        this.lastAdIdx = this.currIdx;
        this.currIdx += 1;
      } else {
        const newPkg = {
          ...pkg,
          items: newItems.length ? newItems : items,
        };
        if (type === 'ad') {
          newPkg.manuallyCuratedAd = true;
        }
        newPackages.push(newPkg);
      }
    });

    return newPackages;
  }

  insertAdsIntoPackage(
    items = [],
    limit = null,
    zone = 1,
    isLastPkg,
  ) {
    const itemsWithAds = [];
    const len = limit ? Math.min(limit, items.length) : items.length;
    let insertedAd = false;

    for (let i = 0; i < len; i += 1) {
      const item = items[i];
      const isLastSlot = isLastPkg && (i >= len - 1);

      if (this.shouldInsertAd() && !isLastSlot) {
        const ad = getAd(this.currIdx.toString(), zone);
        itemsWithAds.push(ad);
        insertedAd = true;

        this.lastAdIdx = this.currIdx;
        this.currIdx += 1;
      }

      itemsWithAds.push(item);
      this.currIdx += 1;
    }

    // make sure to include the rest of the items
    // contained in a threeFeaturedPlusList pkg
    if (limit) {
      const remainingItems = items.slice(limit);
      itemsWithAds.push(...remainingItems);
    }

    return {
      itemsWithAds,
      insertedAd,
    };
  }

  insertAdsIntoWaffle = (items, adType) => {
    const itemsWithAds = [];
    const {
      firstSlot, freq, min, last,
    } = waffleRules[adType];
    if (this.pkgLength < min) return items;

    this.currIdx = 0;
    this.lastAdIdx = -1;

    items.forEach((item) => {
      const insertAtEnd = this.currIdx < this.pkgLength - 1 || last;

      itemsWithAds.push({
        ...item,
      });

      if (item && item.type !== 'ad') {
        this.currIdx += 1;

        if (this.shouldInsertAd(firstSlot, freq) && insertAtEnd) {
          const Ad = getAd(this.currIdx.toString(), 1, adType);
          itemsWithAds.push(Ad);

          this.lastAdIdx = this.currIdx;
        }
      }
    });

    return itemsWithAds;
  }

  shouldInsertAd = (firstSlot = 2, freq = 6) => (this.currIdx === firstSlot && this.lastAdIdx < 0)
    || (this.currIdx - this.lastAdIdx === freq && this.lastAdIdx >= 0);
}

export {
  FrontAdController,
  waffleRules,
};
