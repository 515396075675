import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { concatMap } from 'lib/arrayUtils';
import pipe from 'lib/pipe';

import getPackagesForZone from '../getPackagesForZone';

import './styles.themed.scss';

const addPackageIndex = (layouts) => layouts.reduce((acc, layout) => {
  const { packages: pkgs } = layout;
  const pkgsWithIndex = pkgs.map((pkg, i) => ({ ...pkg, packageIndex: acc.count + i }));
  return {
    allLayouts: [...acc.allLayouts, { ...layout, packages: pkgsWithIndex }],
    count: acc.count + pkgsWithIndex.length,
  };
}, { allLayouts: [], count: 0 }).allLayouts;

const fullBleedPackageTypes = [
  'leadRectangle',
  'magazineLead',
  'collectionsLead',
  'coverSpread',
  'leadSectionFront',
  'showLead',
  'threeFeatured',
  'threeFeaturedPlusList',
  'waffle',
  'bigHeadline',
  'bigStoryTakeover',
  'bigStoryTakeoverPlus2',
  'bigStoryTakeoverPlus3',
  'bigStoryTakeoverPlus4',
  'entityHub',
  'featureLead',
  'accountPromo',
];

const fullBleedFluidWidthPackageTypes = [
  'videoPkg',
  'liveVideo',
];

const isFullBleed = (pkg, isFluidWidthPage) => {
  const firstItemUrl = get(pkg, 'items[0].computedValues.url', '');
  if (firstItemUrl && pkg.type === 'embed' && firstItemUrl.includes('/ramen-layout_full-bleed')) {
    return true;
  }
  if (fullBleedPackageTypes.includes(pkg.type)) {
    return true;
  }
  return isFluidWidthPage && fullBleedFluidWidthPackageTypes.includes(pkg.type);
};

const sliceFullBleedLayouts = (isFluidWidthPage) => (allPkgs) => allPkgs.reduce(
  (layouts, pkg, i) => {
    const fullBleed = isFullBleed(pkg, isFluidWidthPage);

    if (fullBleed) {
      // identify any full bleed package types
      layouts.push({ type: 'fullBleed', packages: [pkg], i });
    } else if (isFluidWidthPage) {
      // set layout type for fluid width if the page qualifies
      if (!layouts.length || layouts[layouts.length - 1].type !== 'fluidWidth') {
        layouts.push({ type: 'fluidWidth', packages: [], i });
      }
      layouts[layouts.length - 1].packages.push(pkg);
    } else {
      // otherwise default to full width type
      if (!layouts.length || layouts[layouts.length - 1].type !== 'fullWidth') {
        layouts.push({ type: 'fullWidth', packages: [], i });
      }
      layouts[layouts.length - 1].packages.push(pkg);
    }
    return layouts;
  }, [],
);

const spliceMidResponsiveAd = (vertical) => (layouts) => {
  if (['news', 'today', 'msnbc'].includes(vertical)) return layouts;
  let hasInsertedAd = false;
  return layouts.map((layout) => ({
    ...layout,
    packages: hasInsertedAd ? layout.packages : concatMap(layout.packages, (pkg) => {
      // Splice midresponsive ad after first 7up on page
      if (!hasInsertedAd && pkg.type === 'sevenUp') {
        hasInsertedAd = true;
        return [pkg, { type: 'midresponsiveAd', zone: 1 }];
      }
      return pkg;
    }),
  }));
};

const getPackageComponents = (context) => (layouts) => layouts.map((layout) => ({
  ...layout,
  packages: getPackagesForZone(1, layout.packages, context),
}));

const FullWidth = ({ packages }, context) => {
  // Package types used for PackageContext before splicing into separate layouts
  const zonePackageTypes = packages.map((pkg) => pkg.type);

  const fullWidthPipeline = pipe(
    sliceFullBleedLayouts(context.isFluidWidthPage),
    spliceMidResponsiveAd(context.vertical),
    addPackageIndex,
    getPackageComponents({ ...context, zonePackageTypes }),
  );

  const { layoutIndex } = context;

  const layouts = fullWidthPipeline(packages);

  return layouts.map((layout) => {
    if (layout.type === 'fullBleed') {
      return (
        <div
          key={layout.i}
          className={classNames(
            'fullBleed',
            `layout-index-${layoutIndex + 1}`,
          )}
          data-testid="full-width"
        >
          {layout.packages}
        </div>
      );
    } if (layout.type === 'fluidWidth') {
      return (
        <div
          key={layout.i}
          className={classNames(
            'fluidWidth',
            `layout-index-${layoutIndex + 1}`,
            'grid-col-push-1-m grid-col-10-m',
          )}
          data-testid="full-width"
        >
          {layout.packages}
        </div>
      );
    }

    // return full width by default if not identified as another type
    return (
      <div
        key={layout.i}
        className={classNames(
          'fullWidth layout-grid-container',
          `layout-index-${layoutIndex + 1}`,
        )}
        data-testid="full-width"
      >
        <div className="layout-grid-item">
          {layout.packages}
        </div>
      </div>
    );
  });
};

FullWidth.propTypes = {
  packages: PropTypes.arrayOf(CustomPropTypes.package).isRequired,
};

FullWidth.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export default FullWidth;
