import { getTaboola } from './getTaboola';
import taboolaRules from './taboolaRules.json';

export class TaboolaController {
  constructor(vertical) {
    this.rules = taboolaRules?.[vertical] ?? {};
    this.totalLayoutParsed = 0;
    this.packages = [];
    this.taboolaIndex = 1;
  }

  addCustomTaboola(packages) {
    const { zonePlacement } = this.rules;
    this.totalLayoutParsed += 1;
    this.packages = [...packages];

    if (zonePlacement === this.totalLayoutParsed) {
      const taboolaPlacement = packages.length;
      this.taboolaIndex += 1;
      this.packages.splice(taboolaPlacement, 0,
        getTaboola(`taboola-${this.taboolaIndex}`));
    }

    return this.packages;
  }
}
