const getLastNonFullWidthLayoutIndex = (layouts) => {
  if (!layouts.length) {
    return -1;
  }
  let lastLayoutWithContentPackage;
  const index = layouts.findIndex(
    (filtered) => filtered.packages[0] && filtered.packages[0].type === 'partnerRecirc',
  );

  // if index exists, that means there is taboola as last package.
  // so if 2nd to last package is not fullWidth set that as lastLayoutContentPackage.
  if (layouts[index - 1] && layouts[index - 1].type !== 'fullWidth') {
    lastLayoutWithContentPackage = index;
  } else {
    // there is no taboola, so let's account for last content package.
    const lastLayoutIndex = layouts.length - 1;
    // if it's full width, we don't have to worry about it
    // since full widths are not included in the ad rules.
    if (layouts[lastLayoutIndex].type !== 'fullWidth') {
      lastLayoutWithContentPackage = lastLayoutIndex + 1;
    }
  }
  return lastLayoutWithContentPackage;
};

export default getLastNonFullWidthLayoutIndex;
