// id is required.
export const getTaboola = (id, zone = 1) => ({
  id,
  items: [],
  metadata: {},
  name: null,
  type: 'taboola',
  zone,
  autofill: null,
  isMidFront: true,
});
