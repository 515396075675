import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT } from 'lib/brandFeatures';
import {
  doesTypeArrayHavePkgTypes,
  getPkgTypesInZone,
  railListPackages,
} from 'lib/packageHelper';

import { Rail, RailContainer } from 'components/Rail';
import MidresponsiveAd from 'components/Ad/Midresponsive';

import getPackagesForZone from '../getPackagesForZone';

import styles from './styles.module.scss';

function RightRail({ packages }, context) {
  const {
    isFirstRail,
    vertical,
    pageRoute,
    isFluidWidthPage,
  } = context;
  const has7LeftColumnsAtLargeBreakpoint = getFeatureConfigForBrand(
    LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT,
    vertical,
  );

  const pkgTypesInMain = getPkgTypesInZone(packages, 1);
  const pkgTypesInRail = getPkgTypesInZone(packages, 2);

  const hasTitle = doesTypeArrayHavePkgTypes(pkgTypesInRail, 'collectionTitle');

  const isMSNBC = vertical === 'msnbc';
  const isNBCHomepage = vertical === 'news' && pageRoute === '/';
  const isMSNBCAndHasListPkg = isMSNBC
    && doesTypeArrayHavePkgTypes(pkgTypesInRail, railListPackages);
  const isMSNBCAndHasSchedulePage = isMSNBC
    && doesTypeArrayHavePkgTypes(pkgTypesInMain, 'schedule');

  const containerClasses = classNames(
    styles.rightRail,
    'layout-rightRail',
    {
      hasTitle,
      [styles.first]: isFirstRail,
      [styles.sub]: !isFirstRail && !isMSNBCAndHasSchedulePage,
      'layout-grid-container': !isFluidWidthPage,
      'grid-col-push-1-m grid-col-10-m': isFluidWidthPage,
    },
  );
  const leftClasses = classNames(
    styles.left,
    'layout-grid-item',
    {
      'grid-col-8-l grid-col-9-xl': isMSNBCAndHasListPkg || isNBCHomepage,
      'grid-col-7-l grid-col-8-xl': has7LeftColumnsAtLargeBreakpoint,
      'grid-col-8-l': !has7LeftColumnsAtLargeBreakpoint && !isMSNBCAndHasListPkg,
    },
  );
  const rightClasses = classNames(
    styles.right,
    'layout-grid-item',
    {
      'grid-col-4-l grid-col-3-xl': isMSNBCAndHasListPkg || isNBCHomepage,
      'grid-col-5-l grid-col-4-xl': has7LeftColumnsAtLargeBreakpoint,
      'grid-col-4-l': !has7LeftColumnsAtLargeBreakpoint,
    },
  );

  return (
    <>
      <RailContainer className={containerClasses}>
        <div className={leftClasses}>
          {getPackagesForZone(1, packages, { ...context, isRailAdjacent: true })}
        </div>
        <Rail className={rightClasses} packageTypes={pkgTypesInRail}>
          {getPackagesForZone(2, packages, { ...context, railContext: 'right' })}
        </Rail>
      </RailContainer>

      {isFirstRail && !isFluidWidthPage && <MidresponsiveAd />}
    </>
  );
}

RightRail.propTypes = {
  packages: PropTypes.arrayOf(CustomPropTypes.package).isRequired,
};

RightRail.contextTypes = {
  ...CustomPropTypes.layoutContext,
  vertical: PropTypes.string,
};

export default RightRail;
