// id is required.
export const getAd = (id, zone = 1, slot = 'boxinline') => ({
  isCustomAd: true,
  sticky: false,
  autofill: null,
  id,
  items: [],
  manuallyCuratedAd: false,
  metadata: { slot, packageId: id },
  name: '',
  type: 'ad',
  zone,
});
