import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { Rail, RailContainer } from 'components/Rail';
import MidresponsiveAd from 'components/Ad/Midresponsive';

import { LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT } from 'lib/brandFeatures';
import Breakpoints from 'lib/Breakpoints';
import BTE from 'lib/BTE';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { hasSingleNav } from 'lib/hasSingleNav';
import {
  getFirstPackageFromPackages,
  getPkgTypesInZone,
  packageHasTitleOrType,
} from 'lib/packageHelper';
import { stub as $t } from '@nbcnews/analytics-framework';

import getPackagesForZone from '../getPackagesForZone';

import './styles.themed.scss';

const LATEST_STORIES_TRACKING_ID = 'mbt_rightRailTabbed_latestClick';

class RightRailTabbed extends Component {
  static propTypes = {
    packages: PropTypes.arrayOf(CustomPropTypes.package).isRequired,
  };

  static contextTypes = {
    ...CustomPropTypes.layoutContext,
  };

  constructor(props) {
    super(props);
    this.state = {
      contentScroll: 0,
      contentTab: true,
      railScroll: 0,
      railTab: false,
      tabbed: true,
    };
  }

  componentDidMount() {
    this.setTabs();

    this.setInitialTabScroll();

    $t('register', LATEST_STORIES_TRACKING_ID);

    BTE.monitorResize();
    BTE.on('resize', this.onResize);
  }

  componentDidUpdate(_prevProps, prevState) {
    const {
      contentScroll,
      contentTab,
      railScroll,
      railTab,
      tabbed,
    } = this.state;

    if (!tabbed) {
      return;
    }

    if (contentTab && prevState.contentTab !== contentTab) {
      window.scrollTo(0, contentScroll);
    }

    if (railTab && prevState.railTab !== railTab) {
      window.scrollTo(0, railScroll);
    }
  }

  onResize = () => {
    this.setTabs();
  };

  setInitialTabScroll = () => {
    const containerTop = this.container.offsetTop - 50;
    this.setState({
      contentScroll: containerTop,
      railScroll: containerTop,
    });
  }

  setTabs = () => {
    this.setState({
      tabbed: !Breakpoints.isLorXL(),
    });
  }

  changeTab = (tab) => {
    const { pageYOffset: scrollY } = window;

    if (tab === 'content') {
      this.setState({ contentTab: true, railTab: false, railScroll: scrollY });
    } else {
      $t('track', LATEST_STORIES_TRACKING_ID);
      this.setState({ contentTab: false, railTab: true, contentScroll: scrollY });
    }
  }

  tabTitle = () => {
    const { packages } = this.props;
    const teaseList = packages.find((pkg) => pkg.type && pkg.type === 'teaseList');

    if (teaseList?.metadata?.title) {
      return teaseList.metadata.title;
    }

    return 'Latest';
  }

  tabs = () => {
    const { tabbed, contentTab, railTab } = this.state;
    const showContentTab = !tabbed || (tabbed && contentTab);
    const showRailTab = !tabbed || (tabbed && railTab);

    const { packages } = this.props;
    const isWaffle = packages.some((pkg) => pkg.type && pkg.type === 'waffle');
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    if (isWaffle || !packages.length) {
      return null;
    }

    return (
      <div
        className={classNames(
          'right-rail-tabbed__tabs',
          { 'right-rail-tabbed__tabs--visible': tabbed },
        )}
      >
        <div
          className={classNames(
            'right-rail-tabbed__tab',
            'js-left-tab',
            { 'right-rail-tabbed__tab--active': showContentTab },
          )}
          onClick={() => { this.changeTab('content'); }}
        >
          <Trans>Top Stories</Trans>
        </div>
        <div
          className={classNames(
            'right-rail-tabbed__tab',
            'js-right-tab',
            { 'right-rail-tabbed__tab--active': showRailTab },
          )}
          onClick={() => { this.changeTab('rail'); }}
        >
          {this.tabTitle()}
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }

  render() {
    const { packages } = this.props;
    const { tabbed, contentTab, railTab } = this.state;
    const { vertical } = this.context;

    const has7LeftColumnsAtLargeBreakpoint = getFeatureConfigForBrand(
      LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT,
      vertical,
    );

    const pkgTypesInRail = getPkgTypesInZone(packages, 2);

    const showContentTab = !tabbed || (tabbed && contentTab);
    const showRailTab = !tabbed || (tabbed && railTab);

    const firstPackageHasTitleOrIsPancake = packageHasTitleOrType(
      getFirstPackageFromPackages(packages),
      'pancake',
    );

    const containerClasses = classNames('layout-grid-container', {
      'right-rail-tabbed__has-title-is-pancake':
        showContentTab && firstPackageHasTitleOrIsPancake,
    });
    const leftClasses = classNames(
      'right-rail-tabbed__container--left layout-grid-item',
      {
        'grid-col-7-l grid-col-8-xl': has7LeftColumnsAtLargeBreakpoint,
        'grid-col-8-l grid-col-9-xl': !has7LeftColumnsAtLargeBreakpoint,
      },
    );
    const rightClasses = classNames(
      'layout-grid-item',
      {
        'grid-col-5-l grid-col-4-xl': has7LeftColumnsAtLargeBreakpoint,
        'grid-col-4-l grid-col-3-xl': !has7LeftColumnsAtLargeBreakpoint,
      },
    );

    const tabClassNames = classNames(
      'layout-rightRailTabbed',
      {
        small: hasSingleNav(vertical),
      },
    );

    return (
      <div className={tabClassNames} ref={(el) => { this.container = el; }}>
        {this.tabs()}

        <RailContainer className={containerClasses}>
          {showContentTab && (
            <div className={leftClasses}>
              {getPackagesForZone(1, packages, { ...this.context, isRailAdjacent: true })}
            </div>
          )}
          {showRailTab && (
            <Rail className={rightClasses} packageTypes={pkgTypesInRail}>
              {getPackagesForZone(2, packages, { ...this.context, railContext: 'right' })}
            </Rail>
          )}
        </RailContainer>

        <MidresponsiveAd />
      </div>
    );
  }
}

export default RightRailTabbed;
