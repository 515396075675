export const LiveBlogAdController = {
  insertBoxinlineAds: function insertBoxinlineAds(liveBlogCards) {
    const cardsWithMobileAds = [];

    liveBlogCards.forEach((card, index) => {
      if (index && !(index % 4)) {
        cardsWithMobileAds.push({
          type: 'ad',
          slot: 'boxinline',
          key: `boxinline:${index}`,
          offsetViewport: 50,
        });
      }

      cardsWithMobileAds.push(card);
    });

    return cardsWithMobileAds;
  },
};
